














































































import SKCarousel from '@/components/SK-Carousel.vue';
import SKHeader from '@/components/SK-Header.vue';
import SKPosition from '@/components/SK-Position.vue';
import axios from '@/utils/axios';
import { IChatMessage } from '@/utils/interfaces';
import { Position } from '@/utils/models';
import { Vue, Component } from 'vue-property-decorator';

@Component({
  components: {
    SKHeader,
    SKCarousel,
    SKPosition
  }
})
export default class ChatBot extends Vue {
  public chatMessages: IChatMessage[] = [];
  public messageInput = '';

  mounted() {
    this.sendMessage('Hi');
    this.chatMessages = [];
    // this.sendMessage('Po');
  }

  public sendMessage(msg: string = this.messageInput): void {
    if (msg.length === 0) return;

    this.chatMessages.push({
      content: msg,
      received: false,
      _id: new Date().getTime()
    });

    const message = encodeURI(msg);
    fetch(
      `https://digiassistant.org/nlp/getResponseComplete/key/123/botName/sportkamasutra_bot/input/${message}/languageCode/de`
    )
      .then(res => res.json())
      .then(async data => {
        const message: IChatMessage = {
          received: true,
          _id: new Date().getTime()
        };
        const { answer, intent } = data;
        if (answer) {
          this.chatMessages.push({ ...message, content: answer });
        } else if (intent && intent.startsWith('train.')) {
          const muscle = encodeURI(intent.split('.')[1]);
          const pso = { type: 'query', input: muscle };

          const idsMen = (await axios.post(`position/search/men`, pso)).data;
          const idsWomen = (await axios.post(`position/search/women`, pso))
            .data;

          message.ids = [
            idsMen.map((x: Position) => x._id),
            idsWomen.map((x: Position) => x._id)
          ];

          this.chatMessages.push(message);
        } else {
          this.chatMessages.push({
            ...message,
            content:
              'I did not understand that. If you need more instructions, please enter "more help".'
          });
        }

        setTimeout(() => {
          window.scrollTo({
            top: document.body.scrollHeight + 50
          });
        }, 100);
      });

    this.messageInput = '';
  }
}
